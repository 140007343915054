#stateslist,
#Stoerer_Briefwahl_imgDiv {
    float: left;
    margin: 0 auto;
    text-align: center;
    width: 19%;
    padding-left: 2rem !important;
}

.topDesign ul#stateslist {
    width: 100%;
    float: left;
}

.shorticon {
    line-height: 1.2rem;
    position: absolute;
    right: 10px;
    top: 0px;
}

.shorticon .downArrow>svg,
.shorticon .upArrow>svg {
    font-size: 11px;
    line-height: 11px;
    background: #fff;
}

.SmartTableOnTaskPopup {
    max-height: 450px;
    position: relative;
    background: #fff;
    top: 5px;
    border: 1px solid #ccc;
    padding-bottom: 6px;
    overflow-y: auto;
    width: 83%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}
ul.stateListTiles {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

ul.stateListTiles > li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 8px;
    width: calc(25% - 12px);
    background: #F5F5F5;
    cursor: pointer;
}

ul.stateListTiles > li > img.stateLogo {
    height: 48px;
    width: auto;
}

ul.stateListTiles > li > span.stateName {
    color: #000;
    font-family: 'PTSans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

ul.stateListTiles > li.state {
    width: 100%;
    text-align: center;
    justify-content: center;
}

ul.stateListTiles > li.active {
    background: #013399;
}

ul.stateListTiles > li.active > span.stateName {
    color: #fff;
}

.CustomSearchInputWithBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    align-content: center;
    flex-wrap: wrap;
}

.CustomSearchInputWithBtn > button.btn.btn-primary {
    height: 48px;
    padding: 12px 16px !important;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

input.CustomSearchInput {
    display: flex;
    padding: 8px 12px 8px 48px;
    align-items: center;
    gap: 10px;
    flex: 1 0;
    align-self: stretch;
    background: #f5f5f5;
    height: 50px !important;
    border: none;
    margin-bottom: 0;
    border-radius: 0 !important;
    font-weight: 700;
}
.CustomSearchInputWithBtn > span.BtnCrossIcon {
    position: absolute;
    left: 12px;
}
.searchItemList {
    border-bottom: 1px solid #E4E4E4;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 16px;
    width: 100%;
}
.BriefwahlInformationPopup .modal-header {
    color: #005437;
    font-family: 'PTSans';
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 !important;
}

.BriefwahlInformationPopup {
    display: flex;
    flex-direction: column;
    gap: 20px;
} 

.BriefwahlInformationPopup .infoBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding: 12px;
    background: #F5F5F5;
}
.BriefwahlInformationPopup .modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}
.BriefwahlInformationPopup .infoBox .infoBox-itemBox {
    display: flex;
    align-items: center;
    gap: 48px;
    align-self: stretch;
}
.BriefwahlInformationPopup .infoBox .infoBox-itemBox .infoBox-itemBox-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
}
.BriefwahlInformationPopup .infoBox .infoBox-itemBox .infoBox-itemBox-item:first-child {
    width: 25%;
}
.BriefwahlInformationPopup .modal-footer {
padding: 0px !important;
}
.BriefwahlInformationPopup .modal-footer button.btn.btn-primary {
    background: #005437 !important;
    border: 1px solid #005437 !important;
}
