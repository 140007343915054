.capctha {
    /* padding: 25px; */
    border-radius: 0px;
    /* box-shadow: 0 1px 20px rgba(0,84, 55, 0.09); */
    background: #ffffff;
    clear: both;
    margin: 25px auto;
    width: 100%;
}
.valign-middle {
    display: flex;
    align-items: center;
}
input[type=text], select, textarea {
  width: 100%;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 3px !important;
  box-sizing: border-box;
  margin-bottom: 15px;
  resize: vertical;
  height: 36px;
}

input[type=text]:focus-visible, textarea:focus-visible {
    border-color:var(--primary-color);
    box-shadow: none; 
    outline: none;
}

    textarea{
    margin-bottom: 15px !important;
}
input[type=submit] {
  background-color: var(--primary-color);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

    input[type=submit]:hover {
        background-color: var(--hover-color);
    }

    #generated-captcha {
        text-decoration: line-through;
        font-weight: bold;
        text-align: center;
        font-size: 20px;
        background-color: #ede7f6;
        border: none;
        padding: 6px;
        outline: none;
        color: var(--text-color);
        width: 70%;
        margin-bottom: 0;
    }

    .c-red {
        color: #f30e27;
    }

    .required-asterisk {
        color: red;
        font-weight: bold;
        margin-left: 1px;    }
    .searchbox_height{ height:36px !important;}
    .mt18{  margin-top:18px;  }